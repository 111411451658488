import React from "react";
import { Link } from "gatsby";  // Import Gatsby's Link
import Logo from "../images/logos/kwd-logo2.jpg";
import Button from "../components/button";

const Impressum = () => {
  return (
    <div>
      <header className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
        <div className="container mx-auto">
          <div className="flex py-5 justify-between items-center">
            <div className="flex flex-row gap-8 items-center">
              {/* Link for scrolling to the top */}
              <Link to="/" className="cursor-pointer">  {/* Use Gatsby Link and set to="/" for the root page */}
                <img className="" src={Logo} width={140} alt="Logo" />
              </Link>
            </div>
            <div className="flex flex-row gap-6">
              <div className="md:flex hidden flex-row gap-4 items-center">
                <Link to="/" className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 cursor-pointer px-4">  {/* Use Gatsby Link with an anchor tag to navigate within the page */}
                  Zurück zur Website
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="container mx-auto py-32">
        <div className="text-center">
          <h1 className="font-display md:text-display-xl text-display-lg font-normal pb-4">
            Impressum
          </h1>
          <p className="text-body-lg font-light text-neutral-700">
            Küchen- und Wohndesign Wieland<br />
			Lehengegend 29, 3213 Frankenfels, Austria<br />
			Tel: 0676/7707716<br />
			E-Mail: dominik@kwd-wieland.at<br />
			<br />
			Tischlerei<br />
			<br />
			UID-Nr: ATU75907814<br />
			<br />
			Mitglied der WKÖ, WKNÖ, Landesinnung Tischler, Bundesinnung Tischler<br />
			<br />
			Berufsrecht: Gewerbeordnung: <a href="http://www.ris.bka.gv.at" target="_blank">www.ris.bka.gv.at</a><br />
			Bezirkshauptmannschaft St. Pölten<br />
			Meisterbetrieb, Meisterprüfung abgelegt in Österreich<br />
			<br />
			Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der EU zu richten: <a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>.<br />
			Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Impressum;
